<template>
  <div>
    <div class="myIcon" :class="{opa:listColor != 'gold'}">
      <span
        class="iconfont icon-fanhuidingbu"
        :class="{ topShow: gotop }"
        @click="toTop"
      ></span
      ><br /><br />
      <span class="iconfont icon-shouye1" @click="toHome"></span>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "myIcon",
  components: {},
  props: {
    listColor:{
      type:String,
      default(){
        return 'white'
      }
    }
  },
  data() {
    return {
      gotop: false,
    };
  },
  created() {},
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  watch: {},
  methods: {
    remove(e){
        window.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 300 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      window.removeEventListener("scroll", this.handleScroll, false);
      if (this.gotop) {
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      }
    },
    toHome() {
      this.jump("/");
    },
  },
};
</script>
<style scoped>
.myIcon {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99;
}
.opa{
  opacity:.3;
}
.myIcon > span {
  font-size: 30px;
  cursor: pointer;
}
.myIcon > span:first-child {
  opacity: 0;
  transform: scale(0);
  transition: 0.5s ease-in-out;
}
.topShow {
  opacity: 1 !important;
  transform: scale(1) !important;
}
</style>